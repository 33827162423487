// material UI components
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

// material UI icons
import { Stack } from "@mui/material";
import { TextField } from "@mui/material";

import { API_URL, axiosConfig } from "../../utils/helpers";
import { useState, useRef } from "react";
//axios instance
import useAxios from "../../hooks/useAxios";

const inititalState = {
  accept: false,
  reject: false,
  update: false,
};

export default function ApproveRequestDialog(props) {
  const [price, setPrice] = useState(
    parseFloat(props.data.credit.payment.price).toFixed(2) || ""
  );
  const [submitting, setSubmitting] = useState(inititalState);
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmitting({ ...submitting, accept: true });

    try {
      const config = axiosConfig({
        method: "PATCH",
        url: API_URL + "/credits/request/",
        data: {
          price: price ? price * 100 : null,
          status: "ACCEPTED",
          creditrequest_id: props.id,
        },
      });
      await axiosInstance.current({
        ...config,
      });
      window.location.reload();
    } catch (error) {
      console.log(error);
    }

    setSubmitting({ ...submitting, accept: false });
  };

  return (
    <Dialog
      onClose={() => props.setApproveOpen(false)}
      open={props.approveOpen}
      fullWidth
    >
      <form onSubmit={handleSubmit}>
        <Stack
          direction="column"
          spacing={4}
          sx={{ padding: "32px 39px 12px 32px" }}
        >
          <Stack direction="column" spacing={2}>
            <Typography
              style={{
                color: "#0F0F0F",
                fontWeight: "500",
                fontSize: "24px",
                lineHeight: "31.25px",
              }}
            >
              Are you sure you want to approve this credit request?
            </Typography>

            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "400",
                color: "#959595",
                lineHeight: "23.44px",
              }}
              align="left"
            >
              Note: Once the credit request is approved, the supervisor will be
              able to proceed with the payment.
            </Typography>
          </Stack>

          <Stack direction="column" spacing={1}>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#6D6D6D",
                lineHeight: "20.83px",
                whiteSpace: "nowrap",
              }}
              textAlign="left"
            >
              Total Price of the Credit Block{" "}
            </Typography>

            <TextField
              type="number"
              placeholder="$"
              InputProps={{ disableUnderline: true }}
              variant="filled"
              size="small"
              value={price}
              onChange={(event) => setPrice(event.target.value)}
              required
              sx={{
                "& input": {
                  padding: "12px",
                },
              }}
            />
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: "#0F0F0F",
                whiteSpace: "nowrap",
              }}
              textAlign="left"
            >
              Price per Credit: $
              {parseInt(price) / parseInt(props.data.credit.added) || "0"}
            </Typography>
          </Stack>
        </Stack>

        <Stack
          direction="column"
          spacing={4}
          sx={{ marginTop: "72px", padding: "32px" }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              cursor="pointer"
              type="submit"
              style={{
                height: "34px",
                color: "#959595",
                cursor: "pointer",
                whiteSpace: "nowrap",
              }}
              onClick={() => props.setApproveOpen(false)}
            >
              Back
            </Typography>

            <Button
              type="submit"
              disabled={submitting.accept}
              variant="contained"
              disableElevation
              color="success"
              style={{
                color: "#fff",
              }}
            >
              Approve Request
            </Button>
          </Stack>
        </Stack>
      </form>
    </Dialog>
  );
}
