//react
import { useEffect, useState, useCallback, memo, useRef } from "react";
//internal
import BasicTable from "../components/BasicTable";
import SearchHeader from "../components/search_header/SearchHeader";
import CustomPagination from "../components/CustomPagination";

import ComponentLoader from "../components/loader/ComponentLoader";
//hoc
import AuthHOC from "../hoc/AuthHOC";
//utils and helper
import { axiosConfig, httpErrorHandler } from "../utils/helpers";
//mui
import { Grid, Paper } from "@mui/material";
//3rd party
import { useSnackbar } from "notistack";
//hooks
import useAxios from "../hooks/useAxios";

const cols = [
  {
    name: "REQUEST ID",
    accessor: "id",
  },
  {
    name: "REQUESTED BY",
    accessor: "supervisor",
    getAccessor: (el) => el.first_name + " " + el.last_name,
  },
  {
    name: "CREDITS REQUESTED",
    accessor: "credit",
    getAccessor: (el) => el.added,
  },
  {
    name: "REQUESTED ON",
    accessor: "credit",
    getAccessor: (el) => el.added_on,
  },

  {
    name: "STATUS",
    accessor: "status",
  },
];
const sortByValues = [
  { label: "All", value: "ALL" },
  {
    label: "Accepted",
    value: "ACCEPTED",
  },
  {
    label: "Received", //receive
    value: "SENT",
  },
  {
    label: "Completed",
    value: "COMPLETED",
  },
  {
    label: "Rejected",
    value: "REJECTED",
  },
];
const filterByMenu = [
  {
    label: "Email",
    value: "email",
  },
  {
    label: "Name",
    value: "first_name",
  },
];

const checkSearchKey = (searchKey) => {
  return Object.values(searchKey).every((value) => {
    if (value === null) {
      return true;
    }
    return false;
  });
}; //if searchKey contains all searchBy values null, it will return the true else false

function CreditManagement(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  //axios instance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [requests, setRequests] = useState([]);
  const paginationCall = useRef(false);
  // const [request, setRequest] = useState(null);
  const [error, setError] = useState(null);
  const [reload, setReload] = useState(false);
  const [searchKey, setSearchKey] = useState({
    first_name: null,
    email: null,
    created_on_from: null,
    created_on_to: null,
    credit_request_status: null,
  });
  const [searching, setSearching] = useState(false);
  // const [paginationCall, setPaginationCall] = useState(false);
  const [page, setPage] = useState(1);
  // const [fetchingCredits, setFetchingCredits] = useState(false);
  const PAGE_SIZE = 10;

  const fetchCredits = useCallback(
    async ({ searchParams, pageValue, abortController }) => {
      try {
        const config = axiosConfig({
          method: "GET",
          uri: `/credits/request`,
          params: {
            ordering: "-created_on",
            page: pageValue ? pageValue : page,
            page_size: PAGE_SIZE,
            ...searchParams,
          },
        });
        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });
        setRequests(response.data.results);
        setCount(response.data.count);
        paginationCall.current = false; //do not remove
      } catch (error) {
        if (error && !error.message === "canceled") {
          setError(error);
          paginationCall.current = false; //do not remove
        }
        httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      }
    },
    [page, enqueueSnackbar, closeSnackbar]
  );

  useEffect(() => {
    const abortController = new AbortController();
    if (checkSearchKey(searchKey) && !paginationCall.current) {
      //will run on page load
      setLoading(true);
      fetchCredits({ abortController })
        .then((e) => {
          if (!abortController?.signal?.aborted) setLoading(false);
        })
        .catch((error) => {
          if (!abortController?.signal?.aborted) setLoading(false);
        });
    }
    return () => {
      return abortController.abort();
    };
  }, [reload, fetchCredits, searchKey]);

  useEffect(() => {
    const abortController = new AbortController();
    if (!checkSearchKey(searchKey) && !paginationCall.current) {
      setPage(1);
      setSearching(true);
      fetchCredits({ searchParams: searchKey, pageValue: 1, abortController })
        .then(() => {
          if (!abortController?.signal?.aborted) setSearching(false);
        })
        .catch(() => {
          if (!abortController?.signal?.aborted) setSearching(false);
        });
    } else if (paginationCall.current && checkSearchKey(searchKey)) {
      setSearching(true);
      fetchCredits({ pageValue: page, abortController })
        .then(() => {
          if (!abortController?.signal?.aborted) setSearching(false);
        })
        .catch((error) => {
          if (!abortController?.signal?.aborted) setSearching(false);
        });
    } else if (paginationCall.current || !checkSearchKey(searchKey)) {
      //(searchKey is not null) This Will do search also for the given key with and pagination on it.
      setSearching(true);
      fetchCredits({
        searchParams: searchKey,
        pageValue: page,
        abortController,
      })
        .then(() => {
          if (!abortController?.signal?.aborted) setSearching(false);
        })
        .catch((error) => {
          if (!abortController?.signal?.aborted) setSearching(false);
        });
    }
    return () => abortController.abort();
  }, [page, searchKey, fetchCredits, reload]);

  return (
    <>
      {loading || error ? (
        <ComponentLoader
          loading={loading}
          error={error}
          sx={{ background: "transparent" }}
          retry={() => {
            setReload((prev) => {
              return true;
            });
          }}
        />
      ) : (
        <Grid container spacing={1} sx={{ height: "100%" }}>
          <Grid item xs={12}>
            <Grid container spacing={5}>
              {/* START: UpSide */}
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="center"
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <SearchHeader
                        disableAddButton={true}
                        searchBarPlaceholder="Search by Name, Email"
                        showDateFilter={true}
                        filterByMenu={filterByMenu}
                        setSearchKey={setSearchKey}
                        sortByValues={sortByValues}
                        showSortBy={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* END: UpSide */}
              {/* START: Down Side */}
              <Grid item xs={12} pb={6}>
                {searching || error ? (
                  <ComponentLoader
                    loading={searching}
                    error={error}
                    sx={{ background: "transparent" }}
                    retry={() => {
                      setReload((prev) => {
                        return !prev;
                      });
                    }}
                  />
                ) : (
                  <Paper elevation={0} sx={{ mb: "2.5rem" }}>
                    <BasicTable
                      cols={cols}
                      rows={requests}
                      loading={searching}
                      // linkKey="id"
                      // linkTo="/credits/requests/"
                      linkKey="id"
                      linkTo="/credits/requests"
                    />
                  </Paper>
                )}
                {requests && (
                  <CustomPagination
                    disabled={loading}
                    last_page_no={Math.ceil(count / PAGE_SIZE)}
                    limit={(requests && requests.length) || 0}
                    handlePaginationChange={(_, value) => {
                      setPage(value);
                      paginationCall.current = true;
                    }}
                  />
                )}
              </Grid>
              {/* END: Down Side */}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default AuthHOC(memo(CreditManagement));
