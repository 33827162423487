// material ui hooks
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// material ui modules
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

// utils
// import SidebarCredits from "./credits/SidebarCredits";
import urls from "../utils/urls.json";

// assets
import credits_icon from "../assets/sidebar/credits-icon.svg";
import dashboard_icon from "../assets/sidebar/dashboard-icon.svg";
import mainuser_icon from "../assets/sidebar/main-users-icon.svg";
import activity_icon from "../assets/sidebar/activity-icon.svg";
import NBScoreLogo from "../assets/logo/Neurobit-Score-Logo-Transparent-2.svg";

//3rd party
import { NavLink } from "react-router-dom";

const sidebar_links = [
  {
    name: "Dashboard",
    icon: (
      <img
        src={dashboard_icon}
        alt="dashboard-icon"
        style={{ marginRight: "14px", width: "20px" }}
      />
    ),
    url: urls.dashboard,
  },
  {
    name: "Credit Management",
    icon: (
      <img
        src={credits_icon}
        alt="credits-icon"
        style={{ marginRight: "14px", width: "20px" }}
      />
    ),
    url: urls.credits,
  },

  {
    name: "Main Users",
    icon: (
      <img
        src={mainuser_icon}
        alt="main-users-icon"
        style={{ marginRight: "14px", width: "20px" }}
      />
    ),
    url: urls.main_users,
  },
  {
    name: "Activity",
    icon: (
      <img
        src={activity_icon}
        alt="activity-icon"
        style={{ marginRight: "14px", width: "20px" }}
      />
    ),
    url: urls.activties,
  },
];

export default function SideBar({
  // drawerWidth,
  open,
  setOpen,
  getSidebarCredits,
}) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const drawerWidth = 300;
  return (
    <Box
      component="nav"
      sx={{
        width: { lg: drawerWidth },
        minHeight: "100vh",
      }}
      className="Sidebar-Component"
    >
      <Drawer
        variant={matches ? "permanent" : "temporary"}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,

            background:
              "linear-gradient(180deg, #04273A 0%, rgba(3, 23, 35, 0.96) 100%)",
          },
        }}
        open={matches ? open : !open}
        onClose={() => setOpen(!open)}
      >
        <Box
          sx={{
            width: "80%",
            display: "flex",
            justifyContent: "center",
            borderBottom: "2.2px solid #4F4F4F",

            mx: "auto",
            pt: "36px",
            pb: "26px",
          }}
        >
          {/* <Typography
            variant="h4"
            sx={{
              textAlign: "center",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "25px",
              lineHeight: "39px",
              color: "#FFFFFF",
            }}
            mt={1}
          >
            Neurobit PSG
          </Typography> */}
          <Box sx={{ width: "130px", minHeight: "65px" }}>
            <img
              src={NBScoreLogo}
              alt="Neurobit Score"
              style={{ width: "130px" }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexFlow: "nowrap column",
            justifyContent: "space-between",
            height: "calc(100vh - 158px)",
          }}
        >
          <Stack sx={{ mt: "32px" }}>
            {sidebar_links.map((element, index) => (
              <NavLink
                to={element.url}
                key={index}
                className="Sidebar-Link"
                style={({ isActive }) => {
                  return {
                    textDecoration: "none",
                    padding: " 20px 27px 20px 27px",
                    backgroundColor: isActive
                      ? "rgba(44, 169, 227, 0.25)"
                      : "transparent",
                    borderLeft: isActive
                      ? "8px solid #2CA9E3"
                      : "8px solid transparent",
                  };
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  {element.icon}
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{
                      color: "#FFF",
                      fontSize: "14px",
                    }}
                    noWrap
                  >
                    {element.name}
                  </Typography>
                </Box>
              </NavLink>
            ))}
          </Stack>
          {/* <SidebarCredits getSidebarCredits={getSidebarCredits} /> */}
        </Box>
      </Drawer>
    </Box>
  );
}
