import { Link, useParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";

// material UI components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

// custom components
import BasicTable from "../components/BasicTable";
import ComponentLoader from "../components/loader/ComponentLoader";

// material UI icons
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { CheckCircleOutline } from "@mui/icons-material";
import { Button, Stack, Box, Breadcrumbs } from "@mui/material";

// utils
import { axiosConfig, httpErrorHandler } from "../utils/helpers";
import ApproveRequestDialog from "../components/forms/approveRequestDialog";
import RevokeRequestDialog from "../components/forms/revokeRequestDialog";
import EditCreditRequestForm from "../components/forms/editRequestForm";
import urls from "../utils/urls.json";
//3rd party
import { useSnackbar } from "notistack";

//hooks
import useAxios from "../hooks/useAxios";

const cols = [
  {
    name: "REQUEST ID",
    accessor: "id",
  },
  {
    name: "REQUESTED BY",
    accessor: "supervisor",
    getAccessor: (el) => el.first_name + " " + el.last_name,
  },
  {
    name: "CREDITS REQUESTED",
    accessor: "credit",
    getAccessor: (el) => el.added,
  },
  {
    name: "REQUESTED ON",
    accessor: "credit",
    getAccessor: (el) => el.added_on,
  },
  {
    name: "PURPOSE",
    accessor: "credit",
    getAccessor: (el) => el.purpose,
  },
  {
    name: "MODE OF PAYMENT",
    accessor: "credit",
    getAccessor: (el) => el.payment.mode.toString(),
  },

  {
    name: "STATUS",
    accessor: "status",
  },
];

export default function CreditRequestsDetails({ user }) {
  const [data, setData] = useState({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  //axios instance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reload, setReload] = useState(false);
  const [approveOpen, setApproveOpen] = useState(false);
  const [revokeOpen, setRevokeOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      try {
        const config = axiosConfig({
          method: "GET",
          uri: `/credits/request/${id}/`,
        });
        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });
        setData(response.data);
        setLoading(false);
      } catch (error) {
        httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
        if (error && !error.message === "canceled") {
          setError(error);
          setLoading(false);
        }
      }
    })();
    return () => abortController.abort();
  }, [id, reload, closeSnackbar, enqueueSnackbar]);

  const breadcrumbs = [
    <Link key="1" to={urls.credits}>
      Credit Management
    </Link>,
    <Typography key="2" color="#969696">
      Credits Request Details
    </Typography>,
  ];

  return (
    <>
      {loading || error ? (
        <ComponentLoader
          loading={loading}
          error={error}
          sx={{ background: "transparent" }}
          retry={() => {
            setReload((prev) => {
              return !prev;
            });
          }}
        />
      ) : (
        <>
          <Grid container spacing={2}>
            {/* START: Breadcrumb */}
            <Grid item xs={12}>
              <Breadcrumbs
                separator={
                  <NavigateNextIcon
                    fontSize="small"
                    sx={{ color: "#969696" }}
                  />
                }
                aria-label="breadcrumb"
              >
                {breadcrumbs}
              </Breadcrumbs>
            </Grid>{" "}
            {/* END: Breadcrumb */}
            {/* START: Body Row */}
            <Grid item xs={12}>
              <Grid style={{ marginTop: "24px" }} item xs={12}>
                <BasicTable cols={cols} rows={[data]} />
              </Grid>
            </Grid>
            {/* START: Payment Details */}
            <Grid item xs={12}>
              <Paper elevation={0} sx={{ padding: "24px" }}>
                <Stack spacing={5}>
                  <Stack
                    spacing={1}
                    direction="column"
                    borderBottom="1px solid #EAEAEA"
                  >
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: "500",
                        color: "#0F0F0F",
                      }}
                    >
                      Payment Details
                    </Typography>
                  </Stack>
                  <Stack direction="column" spacing={5}>
                    <Stack direction="row" spacing={5}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#898989",
                        }}
                      >
                        Mode of Payment
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#0F0F0F",
                        }}
                      >
                        {data.credit.payment.mode === "STRIPE" && "Stripe"}
                        {data.credit.payment.mode === "CHEQUE" && "Cheque"}
                      </Typography>
                    </Stack>

                    <Stack direction="row" spacing={7}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#898989",
                        }}
                      >
                        Payment Status
                      </Typography>

                      <Box
                        sx={{
                          height: "30px",
                          borderRadius: "5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "4px 16px 4px 0px",
                        }}
                      >
                        <Typography component="span">
                          {data.credit.payment.status === "completed"
                            ? "Done"
                            : "Not Done"}
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack direction="row" spacing={7}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#898989",
                        }}
                      >
                        Quoted Amount
                      </Typography>
                      <Box
                        sx={{
                          height: "30px",
                          borderRadius: "5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "4px 16px 4px 0px",
                        }}
                      >
                        <Typography component="span">
                          $
                          {parseFloat(data.credit.payment.price || 0).toFixed(
                            0
                          )}
                        </Typography>
                      </Box>
                    </Stack>
                  </Stack>
                </Stack>
              </Paper>
            </Grid>
            {/* END: Payment Details */}
            {/* START: Supervisor Details */}
            <Grid item xs={12}>
              <Paper elevation={0} sx={{ padding: "24px" }}>
                <Stack spacing={5}>
                  <Stack
                    spacing={1}
                    direction="column"
                    borderBottom="1px solid #EAEAEA"
                  >
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: "500",
                        color: "#0F0F0F",
                      }}
                    >
                      Supervisor Details
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={5}>
                    <Stack direction="column" spacing={5}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#898989",
                        }}
                      >
                        Name
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#898989",
                        }}
                      >
                        Organization
                      </Typography>
                    </Stack>

                    <Stack direction="column" spacing={5}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#0F0F0F",
                        }}
                      >
                        {data.supervisor.first_name}
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#898989",
                        }}
                      >
                        N/A
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Paper>
            </Grid>
            {/* END: Supervisor Details */}
            {data.status === "SENT" && (
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    spacing={2}
                  >
                    <Button
                      disableElevation
                      variant="contained"
                      color="success"
                      style={{ color: "#fff" }}
                      startIcon={<CheckCircleOutline />}
                      onClick={() => setApproveOpen(true)}
                    >
                      Approve Request
                    </Button>
                    <Button
                      disableElevation
                      variant="outlined"
                      color="secondary"
                      onClick={() => setEditOpen(true)}
                    >
                      Update Request
                    </Button>
                  </Stack>
                  <Button
                    disableElevation
                    variant="outlined"
                    // color="inherit"
                    sx={{ color: "#8A8A8A" }}
                    onClick={() => setRevokeOpen(true)}
                  >
                    Revoke Request
                  </Button>
                </Stack>
              </Grid>
            )}
          </Grid>

          {/* Approve Dialog */}
          <ApproveRequestDialog
            data={data}
            approveOpen={approveOpen}
            setApproveOpen={setApproveOpen}
            id={id}
          />
          {/* Revoke */}
          <RevokeRequestDialog
            revokeOpen={revokeOpen}
            setRevokeOpen={setRevokeOpen}
            id={id}
          />
          {/* Edit */}
          <EditCreditRequestForm
            data={data}
            editOpen={editOpen}
            setEditOpen={setEditOpen}
            id={id}
          />
        </>
      )}
    </>
  );
}
