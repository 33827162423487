//react
import { useState, useRef } from "react";
//internals
import ResetPasswordForm from "../components/forms/resetPasswordForm";

//hooks
import useAxios from "../hooks/useAxios";
import useAuth from "../hooks/useAuth";
//utils
import { axiosConfig, httpErrorHandler } from "../utils/helpers";
import urlpatterns from "../utils/urls.json";
//3rd party
import { Navigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

function ResetPassword() {
  const [auth] = useAuth();
  //axios instance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();

  const { id, token } = useParams();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleSubmit = async (event, new_password1, new_password2) => {
    event.preventDefault();
    setLoading(true);
    try {
      const config = axiosConfig({
        method: "POST",
        uri: `/auth/password/reset/confirm/`,
        data: { uid: id, token, new_password1, new_password2 },
      });
      await axiosInstance.current({
        ...config,
      });
      setLoading(false);
    } catch (error) {
      if (error && !error.message === "canceled") setLoading(false);
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
    }
  };

  // useEffect(() => {
  //   let isMounted = true;
  //   if (isMounted) {
  //     console.log("mounted");
  //   }
  //   return () => {
  //     isMounted = false;
  //   };
  // });

  if (auth.authenticated) {
    return <Navigate to={urlpatterns.dashboard} />;
  }

  return (
    <div>
      {/* {response && JSON.stringify(response)} */}
      <ResetPasswordForm loading={loading} handleSubmit={handleSubmit} />
    </div>
  );
}

export default ResetPassword;
