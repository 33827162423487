//react
import { useEffect } from "react";
// 3rd parth modules
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
//MUi
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
// custom hooks and components
import { AuthProvider } from "./context/authContext";
import Layout from "./components/Layout";

// pages;
import Dashboard from "./pages/dashboard";
import SignIn from "./pages/signIn";
import ResetPassword from "./pages/resetPassword";
import Credits from "./pages/creditManagement";
import MainUsers from "./pages/mainUsers";
import MainUsersDetailed from "./pages/MainUsersDetailed";
import Activity from "./pages/Activity";
import Profile from "./pages/profile";

import ErrorPageHandler from "./components/error/ErrorPageHandler";
import Error4xx from "./components/error/Error4xx";
import ForgotPassword from "./pages/forgotPassword";

// utils
import urlpatterns from "./utils/urls.json";

import CreditRequestsDetails from "./pages/creditRequestDetails";

//3rd party
import { SnackbarProvider } from "notistack";
import { GlobalContextProvider } from "./context/globalContext";

const AppRoutes = () => {
  return (
    <Router>
      <GlobalContextProvider>
        <SnackbarProvider
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <AuthProvider>
            <Routes>
              <Route exact path="/" element={<Layout />}>
                <Route index element={<Dashboard />} />
                <Route element={<Credits />} path={urlpatterns.credits} />
                <Route element={<MainUsers />} path={urlpatterns.main_users} />
                <Route
                  element={<MainUsersDetailed />}
                  path={urlpatterns.main_users_detailed}
                />
                <Route element={<Activity />} path={urlpatterns.activties} />

                <Route
                  element={<CreditRequestsDetails />}
                  path={urlpatterns.credit_requests_details}
                />
                <Route exact path={urlpatterns.profile} element={<Profile />} />
              </Route>

              <Route
                exact
                path={urlpatterns.forgot_password}
                element={<ForgotPassword />}
              />
              <Route
                exact
                path={urlpatterns.reset_password}
                element={<ResetPassword />}
              />
              <Route exact path={urlpatterns.signin} element={<SignIn />} />
              <Route exact path={urlpatterns.signin} element={<SignIn />} />

              <Route exact path="*" element={<Error4xx />} />

              <Route
                exact
                path="/error/:error"
                element={<ErrorPageHandler />}
              />
            </Routes>
          </AuthProvider>
        </SnackbarProvider>
      </GlobalContextProvider>
    </Router>
  );
};

export default function App() {
  useEffect(() => {
    localStorage.removeItem("searchText");
  }, []);
  const matches = useMediaQuery("(max-width:1199px)");

  return (
    <>
      {navigator.cookieEnabled ? (
        <Box>
          {matches ? (
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{ height: "100vh" }}
            >
              <Typography
                component="h3"
                sx={{ fontWeight: "700", fontSize: "calc(.5rem + 3vw)" }}
              >
                Oops!! Device Not Supported!
              </Typography>
              <Typography
                component="h3"
                sx={{ fontWeight: "400", fontSize: "calc(.5rem + 1vw)" }}
              >
                This device resolution is not supported.
              </Typography>
            </Stack>
          ) : (
            <Box>
              <AppRoutes />
            </Box>
          )}
        </Box>
      ) : (
        // <Notify message="Cookies are not enabled! Please enable them, we use cookies for authentication" />
        <h2>Cookies not enabled</h2>
      )}
    </>
  );
}
