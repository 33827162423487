//react
import { useState, useEffect, useCallback, useRef } from "react";
//utils and helper
import {
  axiosConfig,
  httpErrorHandler,
  notificationsHandler,
} from "../../utils/helpers";
//hooks
import useAxios from "../../hooks/useAxios";
//mui
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import SvgIcon from "@mui/material/SvgIcon";
//assets/
import { ReactComponent as CreditsSvg } from "../../assets/sidebar/credits-icon.svg";
//3rd party
import { useSnackbar } from "notistack";

export default function AssignServiceForm(props) {
  const { closePopUp, setServicesData, servicesData } = props;
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [services, setServices] = useState([]);
  const [serviceSelected, setServiceSelected] = useState(null);
  // const [defaultPrice, setDefaultPrice] = useState(0);
  const [currentPrice, setCurrentPrice] = useState(0);

  const assignService = useCallback(async () => {
    const getData = () => {
      if (currentPrice) {
        return {
          service: serviceSelected?.id ? serviceSelected.id : null,
          user: props.supervisorId ? props.supervisorId : null,
          price: currentPrice,
        };
      }
      return {
        service: serviceSelected?.id ? serviceSelected.id : null,
        user: props.supervisorId ? props.supervisorId : null,
      };
    };
    setIsLoading(true);
    try {
      const config = axiosConfig({
        method: "POST",
        uri: "/services/assign/",
        data: getData(),
      });
      const response = await axiosInstance.current({
        ...config,
      });
      notificationsHandler(
        "success",
        `${response?.data?.detail}`,
        enqueueSnackbar,
        closeSnackbar
      );
      setServicesData((prevServices) => {
        return [...prevServices, response.data.data];
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error && !error.message === "canceled")
        httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
    }
    closePopUp();
  }, [
    serviceSelected,
    props,
    enqueueSnackbar,
    closeSnackbar,
    setServicesData,
    closePopUp,
    currentPrice,
  ]);

  useEffect(() => {
    const abortController = new AbortController();
    setIsLoading(true);
    (async () => {
      try {
        const config = axiosConfig({
          method: "GET",
          uri: "/services/",
        });
        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });
        setServices(
          response.data?.filter((service) => {
            return !servicesData?.find((assignedService, index) => {
              return service.id === assignedService.service.id;
            });
          })
        );
        setIsLoading(false);
      } catch (error) {
        if (error && !error.message === "canceled") setIsLoading(false);
        httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      }
    })();
    return () => {
      return abortController.abort();
    };
  }, [enqueueSnackbar, closeSnackbar, servicesData]);

  return (
    <Paper sx={{ ...props.sx }} elevation={0}>
      <Stack>
        <Box sx={{ mb: "28px" }}>
          <InputLabel htmlFor="default-price" sx={{ mb: "12px" }}>
            Select a Serivce*
          </InputLabel>
          <Select
            sx={{ width: "100%" }}
            value={
              services?.length
                ? serviceSelected?.name
                  ? serviceSelected.name
                  : ""
                : "No Services Found"
            }
            disabled={!services?.length}
            onChange={(e) => {
              setServiceSelected(
                services.find((service, index) => {
                  return (
                    service.name.toLowerCase() === e.target.value.toLowerCase()
                  );
                })
              );
            }}
          >
            {services.length ? (
              services.map((val, ind) => {
                return (
                  <MenuItem
                    key={ind}
                    value={val?.name ? val?.name : ""}
                    id={val.id}
                  >
                    {val?.name ? val?.name.toUpperCase() : ""}
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem value={"No Services Found"} id={0}>
                {"No Services Found"}
              </MenuItem>
            )}
          </Select>
        </Box>
        <Box sx={{ mb: "28px" }}>
          <InputLabel htmlFor="default-price" sx={{ mb: "12px" }}>
            Default Credits Consumption
          </InputLabel>
          <TextField
            id="default-price"
            value={
              serviceSelected?.default_price ? serviceSelected.default_price : 0
            }
            labelvariant="outlined"
            sx={{ background: "#F6F6F6", width: "100%" }}
            disabled
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon
                    component={CreditsSvg}
                    sx={{
                      color: "transparent",
                      "& path": { stroke: "#888888" },
                    }}
                  ></SvgIcon>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box sx={{ mb: "28px" }}>
          <InputLabel htmlFor="amount" sx={{ mb: "12px" }}>
            Credit Consumption Rate
          </InputLabel>
          <TextField
            onChange={(e) => {
              setCurrentPrice(e.target.value);
            }}
            value={currentPrice}
            id="amount"
            variant="outlined"
            sx={{ background: "#F6F6F6", width: "100%" }}
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon
                    component={CreditsSvg}
                    sx={{
                      color: "transparent",
                      "& path": { stroke: "#888888" },
                    }}
                  ></SvgIcon>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Stack direction="row" justifyContent={"space-between"} mb="10px">
          <Button
            variant="text"
            sx={{
              color: "#6D6D6D",
              px: "16px",
              py: "12px",
            }}
            onClick={(e) => props.closePopUp()}
          >
            Cancel
          </Button>
          <Button
            disabled={
              isLoading ||
              !serviceSelected ||
              currentPrice < 0 ||
              isNaN(currentPrice)
            }
            sx={{
              background: "#2f7ec7",
              color: "#fff",
              ":hover": {
                background: "#286aa7",
              },
              px: "16px",
              py: "12px",
            }}
            onClick={(e) => {
              assignService();
            }}
          >
            Assign Service
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
}

AssignServiceForm.defaultProps = {
  sx: {},
  supervisorId: null,
  closePopUp: () => {},
};
