//react
import { useState, useEffect, useMemo, useCallback, useRef } from "react";
//3rd party
import { Link } from "react-router-dom";

// material UI icons
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

//mui
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Breadcrumbs, Typography } from "@mui/material";
import Icon from "@mui/material/Icon";
//internal

import ComponentLoader from "../components/loader/ComponentLoader";
import UserInfo from "../components/user_detail/UserDetail";
import HorizontalCard from "../components/horizontal_card/HorizontalCard";
import ServiceAccordionSection from "../components/service_accordion/ServiceAccordionSection";
import CreditsAccordionSection from "../components/credits_accordion/CreditsAccordionSection";

//3rd party
import { useLocation, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
//utils and helpers
import { axiosConfig, httpErrorHandler } from "../utils/helpers";
//hoc
import AuthHOC from "../hoc/AuthHOC";
// utils
import urls from "../utils/urls.json";
//assets
import coinSvg from "../assets/dashboard/coin.svg";
import dollarSvg from "../assets/dashboard/dollar.svg";
//hooks
import useAxios from "../hooks/useAxios";
//icons
function CoinIcon(props) {
  return (
    <Icon {...props}>
      <img src={coinSvg} alt="custom-credit-icon" />
    </Icon>
  );
}
function DollarIcon(props) {
  return (
    <Icon {...props}>
      <img src={dollarSvg} alt="custom-credit-icon" />
    </Icon>
  );
}

function MainUsersDetailed(props) {
  let { state } = useLocation();
  //axios instance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  const { id } = useParams();
  const [reload, setReload] = useState(false);
  const [error, setError] = useState(null);
  const [servicesData, setServicesData] = useState([]);
  const [creditsData, setCreditsData] = useState([]);
  const [supervisorData, setSupervisorData] = useState(state ? state : []);
  const [fetchingCreditsData, setFetchingCreditsData] = useState(false);
  const [fetchingSupervisorData, setFetchingSupervisorData] = useState(false);
  const [fetchingServiceData, setFetchingServiceData] = useState(false);
  const [filterBy, setFilterBy] = useState(null);
  const [searching, setSearching] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [count, setCount] = useState(0);
  const previousFilterBy = useRef(null);

  const [page, setPage] = useState(1);
  const PAGE_SIZE = 5;

  //data for user info component
  const userData = useMemo(() => {
    //user Data
    return [
      [
        {
          "First Name": `${supervisorData?.first_name} ${supervisorData?.last_name}`,
        },
        {
          stackStyle: {},
          typographyStyle: {},
        },
      ],
      [
        { Email: `${supervisorData?.email}` },
        {
          stackStyle: {},
          typographyStyle: {},
        },
      ],
      [{ Orgainization: `${supervisorData?.profile?.organization_name}` }],
      [{ Consumer: `${supervisorData?.profile?.consumers}` }],
      [{ "Joined ON": `${supervisorData?.date_joined}` }],
    ];
  }, [supervisorData]);

  //function for fetching supervisor data
  const fetchSupervisorData = useCallback(
    async ({ abortController }) => {
      try {
        const config = axiosConfig({
          method: "GET",
          uri: `/accounts/manager/supervisors/${id}`,
        });
        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });
        setSupervisorData(response.data);
      } catch (error) {
        if (error && !error.message === "canceled") setError(error);
        httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      }
    },
    [id, enqueueSnackbar, closeSnackbar]
  );
  //function for fetching services data
  const fetchServiceData = useCallback(
    async ({ abortController }) => {
      try {
        const config = axiosConfig({
          method: "GET",
          uri: "/services/supervisor/",

          params: {
            supervisor_id: id,
          },
        });
        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });
        setServicesData(response.data);
      } catch (error) {
        if (error && !error.message === "canceled") setError(error);
        httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      }
    },
    [id, enqueueSnackbar, closeSnackbar]
  );
  //function for fetching credits data
  const fetchCreditsData = useCallback(
    async ({ abortController }) => {
      //will check if any change in filterBy and set the page accordingly
      const getPage = () => {
        if (filterBy !== previousFilterBy.current) {
          previousFilterBy.current = filterBy;
          setPage(1);
          return 1;
        } else {
          return page;
        }
      };

      try {
        const config = axiosConfig({
          method: "GET",
          uri: "/credits/",

          params: {
            user_id: id,
            payment_mode: filterBy,
            page: getPage(),
            page_size: PAGE_SIZE,
          },
        });
        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });
        setCreditsData(response.data.results);
        setCount(response.data.count);
      } catch (error) {
        if (error && !error.message === "canceled") setError(error);
        httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      }
    },
    [id, filterBy, enqueueSnackbar, closeSnackbar, page]
  );

  //fetching supervisor data
  useEffect(() => {
    const abortController = new AbortController();
    if (!state) {
      setFetchingSupervisorData(true);
      fetchSupervisorData({ abortController })
        .then(() => {
          if (!abortController?.signal?.aborted)
            setFetchingSupervisorData(false);
        })
        .catch(() => {
          if (!abortController?.signal?.aborted)
            setFetchingSupervisorData(false);
        });
    }
    return () => {
      abortController.abort();
    };
  }, [state, fetchSupervisorData, reload]);

  //fetching service data
  useEffect(() => {
    const abortController = new AbortController();

    setFetchingServiceData(true);
    fetchServiceData({ abortController })
      .then(() => {
        if (!abortController?.signal?.aborted) setFetchingServiceData(false);
      })
      .catch(() => {
        if (!abortController?.signal?.aborted) setFetchingServiceData(false);
      });
    return () => {
      abortController.abort();
    };
  }, [fetchServiceData, reload]);

  //fetching credits data
  useEffect(() => {
    const abortController = new AbortController();
    if (filterBy) {
      setSearching(true);
    } else {
      setSearching(true);
      setFetchingCreditsData(true);
    }
    fetchCreditsData({ abortController })
      .then(() => {
        if (filterBy) {
          if (!abortController?.signal?.aborted) setSearching(false);
        } else {
          setFetchingCreditsData(false);
          if (!abortController?.signal?.aborted) setSearching(false);
        }
      })
      .catch(() => {
        if (filterBy) {
          setSearching(false);
        } else {
          setFetchingCreditsData(false);
        }
      });
    return () => abortController.abort();
  }, [fetchCreditsData, reload, filterBy, page]);

  const breadcrumbs = [
    <Link key="1" to={urls.main_users}>
      Main Users{" "}
    </Link>,
    <Typography key="2" color="#969696">
      Main Users Details{" "}
    </Typography>,
  ];

  return (
    <Paper
      elevation={0}
      sx={{ background: "#f9f9f9", height: "100%", pb: "2rem" }}
    >
      {fetchingServiceData || fetchingSupervisorData || error ? (
        <ComponentLoader
          loading={fetchingServiceData || fetchingSupervisorData}
          error={error}
          sx={{ background: "transparent" }}
          retry={() => {
            setReload((prev) => {
              return !prev;
            });
          }}
        />
      ) : (
        <>
          {/* START: Breadcrumb */}
          <Grid item xs={12} sx={{ mb: "30px" }}>
            <Breadcrumbs
              separator={
                <NavigateNextIcon fontSize="small" sx={{ color: "#969696" }} />
              }
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Grid>{" "}
          {/* END: Breadcrumb */}
          <Grid container spacing={3}>
            <Grid item container columnSpacing={4}>
              <Grid item xs={8}>
                <UserInfo
                  sx={{ width: "auto" }}
                  data={userData}
                  title="User Info"
                  tagLine=""
                />
              </Grid>
              <Grid item container xs={4} rowSpacing={2} alignItems="center">
                <Grid item xs={12}>
                  <HorizontalCard
                    title="Total Credits Purchased"
                    amount={
                      supervisorData?.profile?.credit_summary?.purchased
                        ?.credits_added
                    }
                    Icon={<CoinIcon sx={{ width: "28px" }} />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <HorizontalCard
                    title="Total Amount Paid"
                    amount={
                      supervisorData?.profile?.credit_summary?.purchased
                        ?.amount_paid
                    }
                    Icon={<DollarIcon sx={{ width: "28px", height: "28px" }} />}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ServiceAccordionSection
                title="Services Assigned"
                tagLine={`${
                  servicesData ? servicesData.length : 0
                } services assigned`}
                buttonTitle="Assign Services"
                servicesData={servicesData}
                setServicesData={setServicesData}
                supervisorId={supervisorData.id}
              />
            </Grid>
            <Grid item xs={12}>
              <CreditsAccordionSection
                title="Credits Info"
                tagLine={`${count} Credits Blocks `}
                buttonTitle="Assign Demo Credits"
                creditsData={creditsData}
                setCreditsData={setCreditsData}
                supervisorId={supervisorData.id}
                setFilterBy={setFilterBy}
                searching={searching}
                error={error}
                setPage={setPage}
                count={count}
                setCount={setCount}
                pageSize={PAGE_SIZE}
                fetchingCreditsData={fetchingCreditsData}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Paper>
  );
}

export default AuthHOC(MainUsersDetailed);
