import { useState } from "react";
// custom
import logo from "../../assets/signin/logo.svg";

import Button from "@mui/material/Button";
import {
  Container,
  Paper,
  Typography,
  Stack,
  FormControl,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import urlpatterns from "../../utils/urls.json";

export default function SigninForm(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <Container
      maxWidth={false}
      style={{
        padding: "50px",
        backgroundColor: "#F9F9F9",
        minHeight:"100vh",
        height: "100%",
      }}
    >
      <Stack justifyContent="center" alignItems="center">
        <Paper
          elevation={0}
          style={{
            width: "100%",
            maxWidth: "768px",
            padding: "48px 64px 60px 64px",
            borderRadius: "12px",
            boxShadow: "0px 5px 29px rgba(112, 112, 112, 0.06)",
          }}
        >
          <Stack direction="column">
            <Stack justifyContent="center" alignItems="center">
              <img src={logo} alt="logo" width={108} height={60} />
            </Stack>
            <Stack
              direction="column"
              spacing={2}
              py={5}
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Typography
                style={{
                  fontSize: "28px",
                  fontWeight: "500",
                  lineHeight: "36.46px",
                  letterSpacing: "0em",
                  color: "#0F0F0F",
                }}
              >
                Login to account{" "}
              </Typography>
            </Stack>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              // spacing={2}
            >
              <Stack sx={{ width: "100%" }} spacing={1}>
                <form
                  method="post"
                  onSubmit={(event) =>
                    props.handleSubmit(event, email, password)
                  }
                >
                  <Stack my={2} direction="column" spacing={3}>
                    <FormControl>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#898989",
                        }}
                      >
                        Email address
                      </Typography>
                      <TextField
                        fullWidth
                        inputProps={{
                          style: {
                            padding: "15px",
                            width: "100%",
                            fontSize: "16px",
                            color: "#0F0F0F",
                            fontFamily: "DM Sans",
                          },
                        }}
                        type="email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        required
                        sx={{
                          borderRadius: "5px",
                          backgroundColor: "#FBFBFB",
                          borderColor: "1px solid #C4C4C4",
                          width: "100%",
                        }}
                      />
                    </FormControl>

                    <FormControl>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#898989",
                        }}
                      >
                        Password
                      </Typography>
                      <TextField
                        fullWidth
                        inputProps={{
                          style: {
                            padding: "15px",
                            fontSize: "16px",
                            color: "#0F0F0F",
                            fontFamily: "DM Sans",
                          },
                        }}
                        type="password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        required
                        sx={{
                          borderRadius: "5px",
                          backgroundColor: "#FBFBFB",
                          borderColor: "1px solid #C4C4C4",
                          width: "100%",
                        }}
                      />
                    </FormControl>
                  </Stack>
                  <Stack direction="column" spacing={5} py={2}>
                    <Typography
                      component={Link}
                      to={urlpatterns.forgot_password}
                      style={{
                        cursor: "pointer",
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "19px",
                        letterSpacing: "0em",
                        textAlign: "right",
                        color: "#8A8A8A",
                      }}
                    >
                      Forgot Password?
                    </Typography>
                    <Button
                      disableElevation
                      type="submit"
                      disabled={props.loading}
                      size="large"
                      variant="contained"
                      sx={{
                        backgroundColor: "#2F7EC7",
                        borderRadius: "5px",
                        padding: "15px",
                      }}
                    >
                      LogIn
                    </Button>
                    
                  </Stack>
                
                </form>
              </Stack>
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    </Container>
  );
}
