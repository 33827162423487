import { useState, memo } from "react";
//mui
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";

//internal components
import SortBy from "../sort_by/SortBy";
import CreditsAccordion from "./CreditsAccordion";
import CustomPagination from "../CustomPagination";

import ComponentLoader from "../loader/ComponentLoader";
//forms
import AddDemoCreditForm from "../forms/addDemoCreditForm";
//icon
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

function PopUp(props) {
  // const [open, setOpen] = useState(false);
  const { title, isPopUpOpen, setIsPopUpOpen, InnerComponent } = props;

  const handleClose = (e) => {
    setIsPopUpOpen(false);
  };
  return (
    <Dialog
      onClose={handleClose}
      open={isPopUpOpen}
      fullWidth={true}
      maxWidth="sm"
      sx={{ ".MuiPaper-root": { borderRadius: "16px" } }}
    >
      <DialogTitle align="center">{title}</DialogTitle>
      <DialogContent>{InnerComponent}</DialogContent>
    </Dialog>
  );
}

const sortByValues = [
  { label: "All", value: null },
  { label: "Stripe", value: "STRIPE" },
  {
    label: "Cheque",
    value: "CHEQUE",
  },
  {
    label: "Demo",
    value: "DEMO",
  },
  { label: "Others", value: "OTHER" },
];
function CreditsAccordionSection({
  title,
  tagLine,
  buttonTitle,
  creditsData,
  setCreditsData,
  supervisorId,
  setFilterBy,
  searching,
  error,
  setPage,
  count,
  setCount,
  pageSize,
  fetchingCreditsData,
  sx,
}) {
  const [isAssignPopUpOpen, setIsAssignPopUpOpen] = useState(false);

  // const [, setSelected] = useState(null); //for saving the filter by value

  const openAssignPopUp = (e, data) => {
    setIsAssignPopUpOpen(true);
  };
  const closeAssignPopUp = () => {
    setIsAssignPopUpOpen(false);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        width: "100%",
        padding: "32px",
        ...sx,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={1}
        sx={{ borderBottom: " 1px solid #EAEAEA", pb: "16px", mb: "24px" }}
      >
        <Box>
          <Typography fontSize="20px" fontWeight="500" color="#0F0F0F" mb="8px">
            {title}
          </Typography>
          <Typography fontSize="14px" fontWeight="500" color="#898989">
            {tagLine}
          </Typography>
        </Box>

        <Box sx={{ display: "flex" }}>
          <SortBy
            setSortBy={setFilterBy}
            sortByValues={sortByValues}
            sx={{ mr: "20px" }}
          />
          <Button
            sx={{
              background: "#2f7ec7",
              color: "#fff",
              ":hover": {
                background: "#286aa7",
              },
            }}
            onClick={(e) => {
              openAssignPopUp();
            }}
            startIcon={<AddOutlinedIcon />}
          >
            {buttonTitle}
          </Button>
        </Box>
      </Stack>
      {searching || error ? (
        <ComponentLoader
          loading={searching}
          error={error}
          sx={{ background: "transparent" }}
          retry={() => {
            setFilterBy((prev) => {
              return prev;
            });
          }}
        />
      ) : (
        <>
          {creditsData ? (
            creditsData.map((data, ind) => {
              return (
                <CreditsAccordion
                  data={data}
                  index={ind}
                  key={ind}
                  setCreditsData={setCreditsData}
                />
              );
            })
          ) : (
            <></>
          )}
        </>
      )}
      {creditsData && (
        <CustomPagination
          disabled={fetchingCreditsData}
          last_page_no={Math.ceil(count / pageSize)}
          limit={(creditsData && creditsData.length) || 0}
          handlePaginationChange={(_, value) => setPage(value)}
          sx={{ position: "initial", paddingLeft: 0 }}
        />
      )}

      {/* Assign service pop up */}
      <PopUp
        title="Assign Demo Credits"
        isPopUpOpen={isAssignPopUpOpen}
        setIsPopUpOpen={setIsAssignPopUpOpen}
        InnerComponent={
          <AddDemoCreditForm
            closePopUp={closeAssignPopUp}
            supervisorId={supervisorId}
            setCreditsData={setCreditsData}
            setCount={setCount}
          />
        }
      ></PopUp>
    </Paper>
  );
}

export default memo(CreditsAccordionSection);

CreditsAccordionSection.defaultProps = {
  title: "Title",
  tagLine: "Your tagline will come here.",
  buttonTitle: "Assign Services",
  supervisorId: null,
};
