import { Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import urlpatterns from "../utils/urls.json";
import { DEBUG } from "../utils/helpers";

export default function AuthHOC(WrappedComponent) {
  function Component(props) {
    const [auth, dispatch] = useAuth();
    if (!auth?.authenticated) {
      return <Navigate to={urlpatterns.signin} />;
    } else if (auth?.user?.type === "MANAGER") {
      return <WrappedComponent user={auth.user} dispatch={dispatch} />;
    } else if (auth?.user) {
      if (DEBUG) {
        if (auth?.user?.type === "SUPERVISOR")
          return window.location.replace(`http://localhost:3001`);
        return window.location.replace(`http://localhost:3000`);
      }
      if (auth?.user?.type === "SUPERVISOR")
        return window.location.replace(
          `https://supervisor.score.neurobit.com/`
        );
      return window.location.replace(`https://consumer.score.neurobit.com/`);
    }
  }

  return Component;
}
