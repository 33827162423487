// libraries
import { useState, useRef } from "react";

// material UI styles
import withStyles from "@mui/styles/withStyles";
//hooks
import useAxios from "../../hooks/useAxios";
// material UI components
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import DialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
// import MuiDialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Dialog, MenuItem, Stack } from "@mui/material";

//3rd party
import { useSnackbar } from "notistack";
import { axiosConfig, httpErrorHandler } from "../../utils/helpers";

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const payment = [
  {
    value: "STRIPE",
    label: "STRIPE",
  },
  {
    value: "CHEQUE",
    label: "CHEQUE",
  },
];

export default function EditCreditRequestForm(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  //axios instance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const config = axiosConfig({
        method: "PATCH",
        uri: `/credits/request/`,
        data: {
          creditrequest_id: props.id,
          payment_mode: paymentMode,
          added,
          price,
        },
      });
      await axiosInstance.current({
        ...config,
      });
      window.location.reload();
    } catch (error) {
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
    }
  };
  const [added, setAdded] = useState(
    parseFloat(props.data.credit.added).toFixed(0) || ""
  );
  const [paymentMode, setPaymentMode] = useState(
    props.data.credit.payment.mode || ""
  );
  const [price, setPrice] = useState(
    parseFloat(props.data.credit.payment.price).toFixed(2) || ""
  );

  return (
    <Dialog
      onClose={() => props.setEditOpen(false)}
      open={props.editOpen}
      fullWidth
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={11}>
                <Toolbar>
                  <Box
                    fontWeight={700}
                    fontFamily="DM Sans"
                    fontStyle="normal"
                    width="100%"
                  >
                    <Typography
                      style={{
                        color: "#000000",
                        fontWeight: "500",
                        fontSize: "24px",
                        lineHeight: "31.25px",
                      }}
                      align="center"
                    >
                      Update Credit Request{" "}
                    </Typography>
                  </Box>
                </Toolbar>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" paddingLeft="40px" spacing={2}>
            <Stack direction="column" spacing={1}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#6D6D6D",
                  lineHeight: "20.83px",
                }}
                textAlign="left"
              >
                Number of credits
              </Typography>

              <TextField
                type="number"
                style={{ width: "95%" }}
                InputProps={{ disableUnderline: true }}
                variant="filled"
                size="small"
                value={added}
                onChange={(event) => setAdded(event.target.value)}
                required
              />
            </Stack>
            <Stack direction="column" spacing={1}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "20.83px",
                  color: "#6D6D6D",
                }}
                textAlign="left"
              >
                Total Price
              </Typography>

              <TextField
                type="number"
                style={{ width: "95%" }}
                placeholder="$"
                InputProps={{ disableUnderline: true }}
                variant="filled"
                size="small"
                value={price}
                onChange={(event) => setPrice(event.target.value)}
                required
              />
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "20.83px",
                  color: "#6D6D6D",
                }}
                textAlign="left"
              >
                Price per Credit: ${parseInt(price) / parseInt(added) || "0"}
              </Typography>
            </Stack>

            <Stack direction="column">
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#6D6D6D",
                  lineHeight: "20.83px",
                }}
                textAlign="left"
              >
                Mode of Payment
              </Typography>

              <TextField
                id="outlined-select-currency-native"
                select
                style={{ width: "95%" }}
                InputProps={{ disableUnderline: true }}
                variant="filled"
                size="small"
                value={paymentMode}
                onChange={(event) => setPaymentMode(event.target.value)}
                required
              >
                {payment.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            width="95%"
            paddingLeft="40px"
            sx={{ marginTop: "64px" }}
          >
            <Typography
              variant="button"
              cursor="pointer"
              type="submit"
              style={{
                height: "34px",
                marginBottom: "30px",
                color: "#959595",
                cursor: "pointer",
              }}
              onClick={() => props.setEditOpen(false)}
            >
              Cancel
            </Typography>
            <Button
              variant="contained"
              type="submit"
              style={{
                height: "34px",
                marginBottom: "30px",
                backgroundColor: "#2F7EC7",
                textTransform: "none",
              }}
              disableElevation
            >
              Edit Request
            </Button>
          </Stack>
        </DialogContent>
      </form>
    </Dialog>
  );
}
