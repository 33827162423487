//react
import { useState, useRef } from "react";
//utils
import { axiosConfig, httpErrorHandler } from "../utils/helpers";
import urlpatterns from "../utils/urls.json";
import SigninForm from "../components/forms/signinForm";
//hooks
import useAxios from "../hooks/useAxios";
import useAuth from "../hooks/useAuth";
//3rd party
import { Navigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import axios from "axios";
// import Error from "../components/helpers/error";

export default function SignIn() {
  const [auth, dispatch] = useAuth();
  //axios instance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleSubmit = async (event, email, password) => {
    event.preventDefault();
    setLoading(true);
    try {
      const config = axiosConfig({
        uri: `/auth/login/`,
        method: "POST",
        data: { email, password },
      });
      const response = await axios({
        ...config,
        withCredentials: true,
      });
      dispatch({
        type: "signin",
        payload: {
          user: response.data.user,
          accessToken: {
            token: response.data.access_token,
            expiration: response.data.access_token_expiration,
          },
          refreshToken: {
            token: response.data.refresh_token,
            expiration: response.data.refresh_token_expiration,
          },
        },
        error: null,
      });
      setLoading(false);
    } catch (error) {
      if (error && !error.message === "canceled") setLoading(false);
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
    }
  };
  // useEffect(() => {
  //   let isMounted = true;
  //   if (isMounted) {
  //     console.log("mounted");
  //   }
  //   return () => {
  //     isMounted = false;
  //   };
  // });

  if (auth.authenticated) {
    return <Navigate to={urlpatterns.dashboard} />;
  }

  return (
    <>
      {/* {error && <Error message={JSON.stringify(error)} open={true} />} */}
      <SigninForm loading={loading} handleSubmit={handleSubmit} />
    </>
  );
}
