// React
import { useState } from "react";
import PropTypes from "prop-types";
// MUI
import { Container, Paper, Stack, TextField, Typography } from "@mui/material";
// Forms
import UpdatePasswordForm from "./updatePasswordForm";
// Internal Components
import UpdateNameForm from "./updateName";

export default function UpdateProfileForm(props) {
  // const [firstName, setFirstName] = useState(props.firstName);
  // const [lastName, setLastName] = useState(props.lastName);
  // const [email, setEmail] = useState(props.email);

  const [dataLoading] = useState(false);

  return (
    <>
      {/* START: PROFILE PAGE */}

      <Container
      maxWidth={false}
      style={{
        padding:"0",
        backgroundColor: "#F9F9F9",
        height: "100%",
      }}
    >
      <Paper
        elevation={0}
        sx={{
          // height: "319px",
          width: "100%",
          // mx: "36px",
          // my: "20px",
          // width: "1200px",
          padding: "32px",
        }}
      >
        <Stack
          direction="column"
          spacing={1}
          sx={{ borderBottom: " 1px solid #EAEAEA" }}
        >
          <Typography fontSize="20px" fontWeight="500" color="#0F0F0F">
            Personal info
          </Typography>
          <Typography fontSize="14px" fontWeight="500" color="#898989">
            View & update your personal details here
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          // justifyContent="space-between"
          spacing={2}
          sx={{ my: "38px" }}
        >
          <Stack sx={{ width: "30%" }} direction="column" spacing={5}>
            <Typography fontSize="16px" fontWeight="400" color="#898989">
              Name
            </Typography>{" "}
            <Typography fontSize="16px" fontWeight="400" color="#898989">
              Email address
            </Typography>{" "}
            <Typography fontSize="16px" fontWeight="400" color="#898989">
              Password
            </Typography>
          </Stack>
          <Stack sx={{ width: "100%" }} direction="column" spacing={2}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={4}
            >
              <TextField
                type="text"
                value={(props.firstName && props.firstName) || ""}
                id="first-name"
                inputProps={{
                  style: {
                    padding: "10px",
                    fontSize: "16px",
                    color: "#0F0F0F",
                    fontFamily: "DM Sans",
                  },
                }}
                sx={{
                  borderRadius: "5px",
                  backgroundColor: "#F6F6F6",
                  borderColor: "#E0E0E0",
                  width: "50%",
                }}
              />
              <TextField
                id="last-name"
                type="text"
                value={(props.lastName && props.lastName) || ""}
                inputProps={{
                  style: {
                    padding: "10px",
                    fontSize: "16px",
                    color: "#0F0F0F",
                    fontFamily: "DM Sans",
                  },
                }}
                sx={{
                  // height: "45px",
                  // width: "250px",
                  borderRadius: "5px",
                  backgroundColor: "#F6F6F6",
                  borderColor: "#E0E0E0",
                  width: "50%",
                }}
              />
            </Stack>
            <TextField
              type="email"
              id="email"
              value={(props.email && props.email) || ""}
              // disabled
              inputProps={{
                style: {
                  padding: "10px",
                  fontSize: "16px",
                  color: "#0F0F0F",
                  fontFamily: "DM Sans",
                },
              }}
              sx={{
                borderRadius: "5px",
                backgroundColor: "#F6F6F6",
                borderColor: "#E0E0E0",
                width: "100%",
              }}
            />
            <TextField
              inputProps={{
                style: {
                  padding: "10px",
                  fontSize: "16px",
                  color: "#0F0F0F",
                  fontFamily: "DM Sans",
                },
              }}
              type="password"
              sx={{
                borderRadius: "5px",
                backgroundColor: "#F6F6F6",
                borderColor: "#E0E0E0",
                width: "100%",
              }}
              defaultValue="**********"
              disabled={true}
            />
          </Stack>
          <Stack
            sx={{ width: "100%" }}
            direction="column"
            justifyContent="space-between"
            alignItems="start"
            spacing={11}
          >
            <UpdateNameForm
              dataLoading={dataLoading}
              updateNameHandler={props.updateProfile}
              firstName={(props.firstName && props.firstName) || ""}
              lastName={(props.lastName && props.lastName) || ""}
            />

            <UpdatePasswordForm updatePassword={props.updatePassword} />
          </Stack>
        </Stack>
      </Paper>
      </Container>
      {/* END: PROFILE PAGE */}
    </>
  );
}

UpdateProfileForm.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  updateProfile: PropTypes.func.isRequired,
};
