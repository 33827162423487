//react
import { useState, useCallback, useRef } from "react";
//utils and helper
import {
  axiosConfig,
  httpErrorHandler,
  notificationsHandler,
} from "../../utils/helpers";
//hooks
import useAxios from "../../hooks/useAxios";
//mui
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import SvgIcon from "@mui/material/SvgIcon";

//3rd party
import { useSnackbar } from "notistack";
//assets/
import { ReactComponent as CreditsSvg } from "../../assets/sidebar/credits-icon.svg";

function EditServiceForm(props) {
  const { serviceData, supervisorId, setServicesData, closePopUp } = props;
  //axios instance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isloading, setIsLoading] = useState(false);
  const [, setError] = useState(null);
  const [currentPrice, setCurrentPrice] = useState(
    serviceData?.price ? serviceData?.price : 0
  );

  const unAssignService = useCallback(async () => {
    setIsLoading(true);
    try {
      const config = axiosConfig({
        method: "POST",
        uri: "/services/unassign/",
        data: {
          service: serviceData?.service?.id ? serviceData.service.id : "",
          user: supervisorId ? supervisorId : null,
        },
      });
      const response = await axiosInstance.current({
        ...config,
      });

      notificationsHandler(
        "success",
        `${response.data.detail}`,
        enqueueSnackbar,
        closeSnackbar
      );
      //filter services here
      setServicesData((prevData) => {
        return prevData.filter((service, index) => {
          return serviceData.id !== service.id;
        });
      });
    } catch (error) {
      setError(error);
      if (error && !error.message === "canceled") {
        setIsLoading(false);
        closePopUp();
      }
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
    }
  }, [
    serviceData,
    supervisorId,
    enqueueSnackbar,
    closeSnackbar,
    closePopUp,

    setServicesData,
  ]);

  const updateService = useCallback(async () => {
    setIsLoading(true);
    try {
      const config = axiosConfig({
        method: "POST",
        uri: "/services/supervisor/change-price/",
        data: {
          service_id: serviceData?.service?.id ? serviceData.service.id : null,
          supervisor_id: supervisorId ? supervisorId : null,
          price: currentPrice,
        },
      });
      await axiosInstance.current({
        ...config,
      });
      notificationsHandler(
        "success",
        "Service Price Has Changed Successfully",
        enqueueSnackbar,
        closeSnackbar,
        "",
        10000
      );
      setServicesData((prevData) => {
        return prevData.map((service, ind) => {
          if (service.service.id === serviceData?.service?.id) {
            return { ...service, price: currentPrice };
          }
          return service;
        });
      });
    } catch (error) {
      if (error && !error.message === "canceled") {
        setIsLoading(false);
        closePopUp();
      }
      setError(error);
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
    }
  }, [
    serviceData,
    supervisorId,
    enqueueSnackbar,
    closeSnackbar,
    currentPrice,
    setServicesData,
    closePopUp,
  ]);
  return (
    <Paper sx={{ ...props.sx }} elevation={0}>
      <Stack>
        <Box sx={{ mb: "28px" }}>
          <InputLabel htmlFor="default-price" sx={{ mb: "12px" }}>
            Service
          </InputLabel>
          <TextField
            sx={{ background: "#F6F6F6", width: "100%" }}
            defaultValue={
              serviceData?.service?.name
                ? serviceData?.service?.name.toUpperCase()
                : ""
            }
            disabled
          ></TextField>
        </Box>
        <Box sx={{ mb: "28px" }}>
          <InputLabel htmlFor="default-price" sx={{ mb: "12px" }}>
            Default Credits Consumption
          </InputLabel>
          <TextField
            id="default-price"
            defaultValue={
              serviceData?.service?.default_price
                ? serviceData?.service?.default_price
                : ""
            }
            labelvariant="outlined"
            sx={{ background: "#F6F6F6", width: "100%" }}
            disabled
          />
        </Box>
        <Box sx={{ mb: "48px" }}>
          <InputLabel htmlFor="amount" sx={{ mb: "12px" }}>
            Credit Consumption Rate
          </InputLabel>
          <TextField
            onChange={(e) => {
              setCurrentPrice(e.target.value >= 0 ? e.target.value : 0);
            }}
            value={currentPrice}
            id="amount"
            variant="outlined"
            sx={{ background: "#F6F6F6", width: "100%" }}
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon
                    component={CreditsSvg}
                    sx={{
                      color: "transparent",
                      "& path": { stroke: "#888888" },
                    }}
                  ></SvgIcon>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Stack direction="row" justifyContent={"space-between"} mb="10px">
          <Button
            disabled={isloading}
            variant="outlined"
            sx={{
              borderColor: "#6D6D6D",
              color: "#6D6D6D",
              px: "16px",
              py: "12px",
              ":hover": { background: "#57575708", color: "#000" },
            }}
            onClick={(e) => {
              unAssignService();
            }}
          >
            Unassign Service
          </Button>
          <Button
            sx={{
              background: "#2f7ec7",
              color: "#fff",
              ":hover": {
                background: "#286aa7",
              },
              px: "16px",
              py: "12px",
            }}
            onClick={(e) => {
              updateService();
            }}
          >
            Update Service
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
}

export default EditServiceForm;

EditServiceForm.defaultProps = {
  serviceData: null,
  setServiceData: () => {},
  supervisorId: null,
  servicesData: [],
};
