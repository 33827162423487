//react
import { useState, memo } from "react";
//mui

import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Status from "../status/Status";
//3rd party
import { format } from "date-fns";

function CreditsAccordion(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { data, index } = props;

  const handleAction = () => {
    setIsOpen((prev) => {
      return !prev;
    });
  };
  return (
    <Accordion
      elevation={0}
      sx={{
        background: "#F9F9F9",
        p: "20px",
        borderRadius: "8px",
        cursor: "default",
        ".MuiAccordionSummary-root": {
          cursor: "default",
        },

        mb: "24px",

        "::before": {
          opacity: 0,
        },
      }}
      expanded={isOpen}
    >
      <AccordionSummary>
        <Grid container>
          <Grid item xs={3}>
            <>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "18px",
                  lineHeight: "23px",
                  color: "#0F0F0F",
                }}
              >
                Credit Block-{index + 1}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "18px",
                  color: "#6D6D6D",
                }}
              >
                {data?.added ? parseFloat(data.added).toFixed(2) : 0}
              </Typography>
            </>
          </Grid>

          <Grid item xs={9}>
            <Box sx={{ display: "flex", float: "right" }}>
              <Typography
                onClick={(e) => {
                  handleAction();
                }}
                sx={{
                  display: "inline-block",
                  float: "right",
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "21px",

                  color: "#2f7ec7",
                  ":hover": {
                    color: "#215586",
                  },
                }}
              >
                {isOpen ? "Show Less" : "Show More"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          sx={{
            mb: "24px",
            borderTop: "1px solid #D0D5DD",
            py: "24px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            columnGap: "10px",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "18px",
                mb: "16px",
                color: "#6D6D6D",
                textTransform: "uppercase",
              }}
            >
              Credits Assigned
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "21px",
                mb: "16px",
                color: "#0F0F0F",
              }}
            >
              {data?.added ? parseFloat(data?.added).toFixed(2) : 0}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "18px",
                mb: "16px",
                color: "#6D6D6D",
                textTransform: "uppercase",
              }}
            >
              Price
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "21px",
                mb: "16px",
                color: "#0F0F0F",
              }}
            >
              {data?.payment?.price
                ? parseFloat(data?.payment.price).toFixed(2)
                : "-"}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "18px",
                mb: "16px",
                color: "#6D6D6D",
                textTransform: "uppercase",
              }}
            >
              Purpose
            </Typography>
            <Typography sx={{ mb: "16px" }}>
              <span
                title={data?.purpose ? data?.purpose : "-"}
                style={{
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "21px",
                  width: "100%",
                  overflow: "hidden",
                  whiteSpace: "no-wrap",
                  color: "#0F0F0F",
                  textOverflow: "ellipsis",
                }}
              >
                {data?.purpose ? data?.purpose : "-"}
              </span>
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "18px",
                mb: "16px",
                color: "#6D6D6D",
                textTransform: "uppercase",
              }}
            >
              Used
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "21px",
                mb: "16px",
                color: "#0F0F0F",
              }}
            >
              {data?.used ? parseFloat(data?.used).toFixed(2) : "-"}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "18px",
                mb: "16px",
                color: "#6D6D6D",
                textTransform: "uppercase",
              }}
            >
              Available
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "21px",
                mb: "16px",
                color: "#0F0F0F",
              }}
            >
              {data?.available ? parseFloat(data?.available).toFixed(2) : "-"}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "18px",
                mb: "16px",
                color: "#6D6D6D",
                textTransform: "uppercase",
              }}
            >
              Added On
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "21px",
                mb: "16px",
                color: "#0F0F0F",
              }}
            >
              {data?.added_on
                ? format(new Date(data.added_on), "dd MMM yyyy")
                : "-"}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "18px",
                mb: "16px",
                color: "#6D6D6D",
                textTransform: "uppercase",
              }}
            >
              Valid Till
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "21px",
                mb: "16px",
                color: "#0F0F0F",
              }}
            >
              {data?.validity
                ? format(new Date(data.validity), "dd MMM yyyy")
                : "-"}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "18px",
                mb: "16px",
                color: "#6D6D6D",
                textTransform: "uppercase",
              }}
            >
              Payment Mode
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "21px",
                mb: "16px",
                color: "#0F0F0F",
              }}
            >
              {data?.payment?.mode ? data?.payment?.mode : "-"}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "18px",
                mb: "16px",
                color: "#6D6D6D",
                textTransform: "uppercase",
              }}
            >
              Status
            </Typography>
            <Status status={data?.active ? "Active" : "Inactive"} />
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export default memo(CreditsAccordion);

CreditsAccordion.defaultProps = {
  index: 0,
  data: [],
};
