//react
import { useState, useCallback, useRef } from "react";
//utils and helper
import {
  axiosConfig,
  httpErrorHandler,
  notificationsHandler,
} from "../../utils/helpers";
//internal
//hooks
import useAxios from "../../hooks/useAxios";
//mui
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

//3rd party
import { useSnackbar } from "notistack";

export default function AddDemoCreditForm(props) {
  const { supervisorId, closePopUp, setCreditsData, setCount } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  // const [, setError] = useState(null);
  const [creditsCount, setCreditsCount] = useState(0);
  const [perCreditPrice, setPerCreditPrice] = useState(1);
  const [purpose, setPurpose] = useState("");
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();

  const assignDemoCredits = useCallback(async () => {
    setIsLoading(true);
    try {
      const config = axiosConfig({
        method: "POST",
        uri: "/credits/",
        data: {
          added: creditsCount,
          supervisor: supervisorId,
          payment: {
            mode: "DEMO",
            price: perCreditPrice * creditsCount * 100,
          },
          purpose: purpose.length > 2 ? purpose : null,
        },
      });
      const response = await axiosInstance.current({
        ...config,
      });

      setCreditsData((prev) => {
        return [...prev, response.data];
      });
      notificationsHandler(
        "success",
        "Demo Credits Assigned Successfully",
        enqueueSnackbar,
        closeSnackbar
      );
      setCount((prev) => prev + 1);
      closePopUp();
      setIsLoading(false);
    } catch (error) {
      if (error && !error.message === "canceled") setIsLoading(false);
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
    }
  }, [
    creditsCount,
    perCreditPrice,
    purpose,
    supervisorId,
    setCreditsData,
    closePopUp,
    enqueueSnackbar,
    closeSnackbar,
    setCount,
  ]);

  return (
    <Paper sx={{ ...props.sx }} elevation={0}>
      <Stack>
        <Box sx={{ mb: "28px" }}>
          <InputLabel htmlFor="number-of-credits" sx={{ mb: "12px" }}>
            Number of Credits*
          </InputLabel>
          <TextField
            id="number-of-credits"
            value={creditsCount}
            onChange={(e) => {
              setCreditsCount(e.target.value);
            }}
            type="number"
            variant="outlined"
            sx={{ background: "#F6F6F6", width: "100%" }}
          />
        </Box>
        <Box sx={{ mb: "28px" }}>
          <InputLabel htmlFor="price-per-credit" sx={{ mb: "12px" }}>
            Price Per Credit
          </InputLabel>
          <TextField
            onChange={(e) => {
              setPerCreditPrice(e.target.value);
            }}
            value={perCreditPrice}
            id="price-per-credit"
            variant="outlined"
            sx={{ background: "#F6F6F6", width: "100%" }}
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Box>
        <Box sx={{ mb: "28px" }}>
          <InputLabel htmlFor="purpose" sx={{ mb: "12px" }}>
            Purpose*
          </InputLabel>
          <TextField
            id="purpose"
            value={purpose}
            onChange={(e) => {
              setPurpose(e.target.value);
            }}
            variant="outlined"
            sx={{ background: "#F6F6F6", width: "100%" }}
          />
        </Box>
        <Box sx={{ mb: "28px" }}>
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "21px",
              color: "#6D6D6D",
            }}
          >
            Total Amount
          </Typography>
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: "20px",
              lineHeight: "26px",
              color: "#0f0f0f",
            }}
          >
            {perCreditPrice * creditsCount}
          </Typography>
        </Box>
        <Stack direction="row" justifyContent={"space-between"} mb="10px">
          <Button
            variant="text"
            sx={{
              color: "#6D6D6D",
              px: "16px",
              py: "12px",
            }}
            onClick={(e) => closePopUp()}
          >
            Cancel
          </Button>
          <Button
            disabled={
              !(creditsCount >= 1) ||
              isNaN(creditsCount) ||
              isLoading ||
              !(perCreditPrice >= 1)
            }
            sx={{
              background: "#2f7ec7",
              color: "#fff",
              ":hover": {
                background: "#286aa7",
              },
              px: "16px",
              py: "12px",
            }}
            onClick={(e) => {
              assignDemoCredits();
            }}
          >
            Assign Demo Credits
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
}

AddDemoCreditForm.defaultProps = {
  supervisorId: null,
  setCreditsData: () => {},
  closePopUp: () => {},
};
