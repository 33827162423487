import { useState, useRef } from "react";
import { Navigate } from "react-router-dom";
import ForgotPasswordForm from "../components/forms/forgotPasswordForm";
import useAuth from "../hooks/useAuth";
import {
  axiosConfig,
  httpErrorHandler,
  notificationsHandler,
} from "../utils/helpers";
import urlpatterns from "../utils/urls.json";

//3rd party
import { useSnackbar } from "notistack";
//hooks
import useAxios from "../hooks/useAxios";

function ForgotPassword() {
  const [auth] = useAuth();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  //axios instance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  const handleSubmit = async (event, email) => {
    event.preventDefault();
    setLoading(true);
    try {
      const config = axiosConfig({
        method: "POST",
        uri: `/auth/password/reset/`,
        data: { email },
      });
      const response = await axiosInstance.current({
        ...config,
      });
      notificationsHandler(
        "success",
        `${response.data.detail}`,
        enqueueSnackbar,
        closeSnackbar
      );
      setLoading(false);
    } catch (error) {
      if (error && !error.message === "canceled") setLoading(false);
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
    }
  };

  // useEffect(() => {
  //   let isMounted = true;
  //   if (isMounted) {
  //     // console.log("mounted");
  //   }
  //   return () => {
  //     isMounted = false;
  //   };
  // });

  if (auth.authenticated) {
    return <Navigate to={urlpatterns.dashboard} />;
  }

  return (
    <div>
      <ForgotPasswordForm loading={loading} handleSubmit={handleSubmit} />
    </div>
  );
}

export default ForgotPassword;
