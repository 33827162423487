// material UI components
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Stack } from "@mui/material";
//utils
import { axiosConfig, httpErrorHandler } from "../../utils/helpers";
//react
import { useState, useRef } from "react";
//hooks
import useAxios from "../../hooks/useAxios";
//3rd party
import { useSnackbar } from "notistack";

export default function RevokeRequestDialog(props) {
  //axios instance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  const [, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const config = axiosConfig({
        method: "PATCH",
        uri: `/credits/request/`,
        data: { status: "REJECTED", creditrequest_id: props.id },
      });
      await axiosInstance.current({
        ...config,
      });
      window.location.reload();
    } catch (error) {
      window.location.reload();
      if (error && !error.message === "canceled") {
        setLoading(false);
      }
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
    }
  };

  return (
    <Dialog
      onClose={() => props.setRevokeOpen(false)}
      open={props.revokeOpen}
      fullWidth
    >
      <DialogTitle>
        <Stack direction="column" spacing={3}>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography
                  style={{
                    color: "#0F0F0F",
                    fontWeight: "500",
                    fontSize: "24px",
                  }}
                  align="left"
                >
                  Are you sure you want to Revoke the Request?{" "}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Stack direction="column">
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "400",
                color: "#959595",
              }}
              align="left"
            >
              Once the credit request is revoked, all the requested details will
              be permenantly deleted.{" "}
            </Typography>
          </Stack>

          <Stack direction="column" spacing={4}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="button"
                cursor="pointer"
                type="submit"
                style={{
                  height: "34px",
                  color: "#959595",
                  cursor: "pointer",
                }}
                onClick={() => props.setRevokeOpen(false)}
                disableElevation
              >
                Back
              </Typography>
              <Button
                variant="contained"
                onClick={handleSubmit}
                disableElevation
                color="primary"
                style={{
                  height: "34px",
                  marginBottom: "15px",
                  backgroundColor: "#2F7EC7",
                  textTransform: "none",
                }}
                type="submit"
              >
                Revoke Request
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </DialogTitle>
    </Dialog>
  );
}
