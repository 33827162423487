//react
import React from "react";
//mui
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";

function HorizontalCard({ title, amount, Icon }) {
  return (
    <Paper
      elevation={0}
      sx={{
        boxShadow: "0px 3px 20px 4px rgba(0, 0, 0, 0.04)",
        borderRadius: "8px",

        py: "37.5px",
        px: "24px",
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "18px",
              color: "#4A4A4A",
              mb: "12px",
              textTransform: "uppercase",
            }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={1} sx={{ minWidth: "30px" }}>
              {Icon}
            </Grid>
            <Grid item xs={10}>
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "24px",
                  lineHeight: "31px",

                  color: "#4A4A4A",
                }}
              >
                {amount ? amount : 0}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

HorizontalCard.defaultProps = {
  title: "Title",
  amount: 0,
  Icon: <></>,
};

export default HorizontalCard;
